import { FunctionComponent, SVGProps } from "react";
import InfiniteLoopScroll from "@/components/homepage/InfiniteLoopScroll";

const investors = [
  {
    img: "emurgo.png",
    style: {
      width: "147px",
      height: "36px",
    },
  },
  {
    img: "bitmart.png",
    style: {
      width: "166px",
      height: "124px",
    },
  },
  {
    img: "lc.png",
    style: {
      width: "144px",
      height: "65px",
    },
  },
  {
    img: "ms.png",
    style: {
      width: "192px",
      height: "55px",
    },
  },
  {
    img: "uc.png",
    style: {
      width: "223px",
      height: "44px",
    },
  },
  {
    img: "neo.png",
    style: {
      width: "106px",
      height: "33px",
    },
  },
];

const Investors: FunctionComponent = () => {
  return (
    <div className="layout-block layout-content  px-5 py-[60px] sm:py-[100px]  sm:px-28">
      <div className="text-[32px] font-bold">Our investors</div>
      <div className="relative mx-auto flex-shrink-0 flex-col overflow-hidden">
        <InfiniteLoopScroll
          duration={"18000ms"}
          animation="loopInvestor"
          className="items-center"
        >
          {investors.map((investor) => (
            <img
              src={`/assets/home/investors/${investor.img}`}
              key={investor.img}
              style={investor.style}
              className="mr-[60px]"
            />
          ))}
        </InfiniteLoopScroll>
        <div
          className="pointer-events-none absolute inset-0"
          style={{
            background:
              "linear-gradient(90deg, #ffffff, transparent 20%, transparent 80%, #ffffff)",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Investors;
