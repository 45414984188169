import { FunctionComponent, PropsWithChildren } from "react";

const InfiniteLoopSlider: FunctionComponent<
  {
    duration: string;
    direction?: string;
    className?: string;
    animation?: string;
  } & PropsWithChildren
> = ({
  children,
  duration,
  direction = "normal",
  className = "",
  animation = "loop",
}) => {
  return (
    <div
      className={`flex w-fit ${className}`}
      style={{
        animationName: animation,
        animationTimingFunction: "linear",
        animationIterationCount: "infinite",
        animationDirection: direction,
        animationDuration: duration,
      }}
    >
      {children}
      {children}
    </div>
  );
};

export default InfiniteLoopSlider;
