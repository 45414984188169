import { FunctionComponent, SVGProps } from "react";
import { CoinIcon } from "@/components/common/icons";

const DiscordIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="250"
      height="135"
      viewBox="0 0 250 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.2">
        <g filter="url(#filter0_f_3619_22649)">
          <path
            d="M185.072 -15.8638C173.988 -21.0549 161.988 -24.8227 149.488 -26.9996C149.378 -27.0031 149.269 -26.9825 149.168 -26.9391C149.068 -26.8957 148.977 -26.8306 148.904 -26.7484C147.404 -23.9854 145.654 -20.3851 144.488 -17.6221C131.229 -19.6315 117.745 -19.6315 104.487 -17.6221C103.32 -20.4688 101.57 -23.9854 99.9864 -26.7484C99.9031 -26.9159 99.6531 -26.9996 99.4031 -26.9996C86.9027 -24.8227 74.9857 -21.0549 63.8188 -15.8638C63.7354 -15.8638 63.6521 -15.7801 63.5688 -15.6963C40.9015 18.381 34.6513 51.5373 37.7347 84.3587C37.7347 84.5262 37.8181 84.6937 37.9847 84.7774C52.9851 95.8295 67.4022 102.528 81.6526 106.965C81.9026 107.049 82.1526 106.965 82.2359 106.798C85.5694 102.193 88.5695 97.3366 91.1529 92.2292C91.3195 91.8943 91.1529 91.5593 90.8195 91.4756C86.0694 89.6336 81.5693 87.4567 77.1525 84.9448C76.8191 84.7774 76.8191 84.275 77.0691 84.0238C77.9858 83.354 78.9025 82.6004 79.8192 81.9306C79.9859 81.7632 80.2359 81.7632 80.4026 81.8469C109.07 94.9922 139.988 94.9922 168.322 81.8469C168.488 81.7632 168.738 81.7632 168.905 81.9306C169.822 82.6842 170.738 83.354 171.655 84.1076C171.988 84.3587 171.988 84.8611 171.572 85.0286C167.238 87.6241 162.655 89.7173 157.905 91.5593C157.571 91.6431 157.488 92.0617 157.571 92.3129C160.238 97.4203 163.238 102.277 166.488 106.882C166.738 106.965 166.988 107.049 167.238 106.965C181.572 102.528 195.989 95.8295 210.989 84.7774C211.156 84.6937 211.239 84.5262 211.239 84.3587C214.906 46.4299 205.156 13.5248 185.405 -15.6963C185.322 -15.7801 185.239 -15.8638 185.072 -15.8638ZM95.4863 64.3477C86.9027 64.3477 79.7359 56.3936 79.7359 46.5974C79.7359 36.8012 86.7361 28.847 95.4863 28.847C104.32 28.847 111.32 36.8849 111.237 46.5974C111.237 56.3936 104.237 64.3477 95.4863 64.3477ZM153.571 64.3477C144.988 64.3477 137.821 56.3936 137.821 46.5974C137.821 36.8012 144.821 28.847 153.571 28.847C162.405 28.847 169.405 36.8849 169.322 46.5974C169.322 56.3936 162.405 64.3477 153.571 64.3477Z"
            fill="url(#paint0_linear_3619_22649)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_3619_22649"
          x="33"
          y="-31"
          width="183"
          height="142"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_3619_22649"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3619_22649"
          x1="124.5"
          y1="-27"
          x2="124.5"
          y2="107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#87B5FF" />
          <stop offset="1" stop-color="#ADB5FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const EmailIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="250"
      height="135"
      viewBox="0 0 250 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.2">
        <g filter="url(#filter0_f_3619_22682)">
          <path
            d="M194.5 -34H54.5C44.875 -34 37.0875 -26.125 37.0875 -16.5L37 88.5C37 98.125 44.875 106 54.5 106H194.5C204.125 106 212 98.125 212 88.5V-16.5C212 -26.125 204.125 -34 194.5 -34ZM191 3.1875L129.137 41.8625C126.337 43.6125 122.663 43.6125 119.863 41.8625L58 3.1875C57.1226 2.69497 56.3543 2.02954 55.7415 1.23147C55.1288 0.433407 54.6843 -0.480693 54.435 -1.45551C54.1858 -2.43032 54.1369 -3.44557 54.2914 -4.43982C54.4458 -5.43406 54.8004 -6.38663 55.3337 -7.23987C55.867 -8.0931 56.5678 -8.82926 57.3939 -9.40379C58.2199 -9.97832 59.1539 -10.3793 60.1393 -10.5824C61.1248 -10.7855 62.1412 -10.7865 63.1271 -10.5855C64.113 -10.3844 65.0478 -9.98533 65.875 -9.4125L124.5 27.25L183.125 -9.4125C183.952 -9.98533 184.887 -10.3844 185.873 -10.5855C186.859 -10.7865 187.875 -10.7855 188.861 -10.5824C189.846 -10.3793 190.78 -9.97832 191.606 -9.40379C192.432 -8.82926 193.133 -8.0931 193.666 -7.23987C194.2 -6.38663 194.554 -5.43406 194.709 -4.43982C194.863 -3.44557 194.814 -2.43032 194.565 -1.45551C194.316 -0.480693 193.871 0.433407 193.258 1.23147C192.646 2.02954 191.877 2.69497 191 3.1875Z"
            fill="url(#paint0_linear_3619_22682)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_3619_22682"
          x="33"
          y="-38"
          width="183"
          height="148"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_3619_22682"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3619_22682"
          x1="124.5"
          y1="-34"
          x2="124.5"
          y2="106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#87B5FF" />
          <stop offset="1" stop-color="#ADB5FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const TwitterIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="250"
      height="135"
      viewBox="0 0 250 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.2">
        <g filter="url(#filter0_f_4072_1741)">
          <path
            d="M212.5 -35.4956C206.064 -32.3685 199.151 -30.2557 191.883 -29.3007C199.382 -34.2211 204.993 -41.9654 207.668 -51.0883C200.622 -46.4993 192.911 -43.2692 184.87 -41.5383C179.462 -47.8695 172.3 -52.0659 164.494 -53.4761C156.689 -54.8862 148.677 -53.4311 141.703 -49.3368C134.729 -45.2424 129.183 -38.7378 125.926 -30.8329C122.669 -22.928 121.883 -14.065 123.69 -5.61993C109.414 -6.40594 95.4477 -10.4748 82.6984 -17.5625C69.9491 -24.6502 58.7013 -34.5983 49.6851 -46.7612C46.6022 -40.9298 44.8296 -34.1687 44.8296 -26.9681C44.8261 -20.4859 46.2819 -14.1031 49.0676 -8.38579C51.8533 -2.66852 55.8829 2.20637 60.7989 5.80634C55.0977 5.60741 49.5223 3.91816 44.5367 0.879183V1.38626C44.5361 10.4778 47.404 19.2895 52.6538 26.3263C57.9036 33.363 65.2118 38.1914 73.3385 39.9922C68.0497 41.5617 62.5048 41.7929 57.1226 40.6683C59.4154 48.491 63.8818 55.3316 69.8963 60.2326C75.9107 65.1335 83.1723 67.8494 90.6644 68C77.9462 78.9481 62.2393 84.8866 46.0704 84.8605C43.2063 84.8614 40.3446 84.678 37.5 84.3112C53.9123 95.8826 73.0175 102.024 92.5295 102C158.58 102 194.689 42.012 194.689 -10.0146C194.689 -11.7049 194.65 -13.4121 194.581 -15.1024C201.604 -20.6721 207.667 -27.569 212.485 -35.4702L212.5 -35.4956V-35.4956Z"
            fill="url(#paint0_linear_4072_1741)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_4072_1741"
          x="33.5"
          y="-58"
          width="183"
          height="164"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_4072_1741"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4072_1741"
          x1="125"
          y1="-54"
          x2="125"
          y2="102"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#87B5FF" />
          <stop offset="1" stop-color="#ADB5FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const SteamIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="250"
      height="135"
      viewBox="0 0 250 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.2">
        <g filter="url(#filter0_f_3619_22652)">
          <path
            d="M124.5 -64C135.991 -64 147.369 -61.7367 157.985 -57.3395C168.601 -52.9422 178.247 -46.497 186.372 -38.3718C194.497 -30.2467 200.942 -20.6008 205.339 -9.9848C209.737 0.631187 212 12.0093 212 23.5C212 46.7064 202.781 68.9624 186.372 85.3718C169.962 101.781 147.706 111 124.5 111C84.25 111 50.5625 84.05 40.15 47.3875L73.6625 61.2125C74.8154 66.8152 77.8639 71.8495 82.2946 75.4674C86.7252 79.0852 92.2674 81.0656 97.9875 81.075C111.638 81.075 122.75 69.9625 122.75 56.3125V55.175L152.5 33.9125H153.2C171.4 33.9125 186.188 19.125 186.188 0.925003C186.188 -17.275 171.4 -32.0625 153.2 -32.0625C135 -32.0625 120.125 -17.275 120.125 0.925003V1.3625L99.3875 31.6375L97.9875 31.55C92.825 31.55 88.0125 33.125 84.075 35.8375L37 16.5C40.7625 -28.5625 78.3875 -64 124.5 -64ZM91.95 68.7375C98.95 71.625 107 68.3875 109.887 61.3875C112.775 54.3875 109.45 46.425 102.625 43.5375L91.425 38.9C95.7125 37.325 100.525 37.2375 105.075 39.1625C109.712 41 113.3 44.5875 115.138 49.225C117.062 53.775 117.062 58.85 115.138 63.4C111.375 72.85 100.262 77.4 90.8125 73.4625C86.4375 71.625 83.1125 68.3 81.275 64.3625L91.95 68.7375ZM175.25 0.925003C175.25 13.0875 165.363 22.975 153.2 22.975C147.367 22.9519 141.781 20.6185 137.665 16.4858C133.549 12.3532 131.237 6.7579 131.238 0.925003C131.226 -1.9624 131.786 -4.82352 132.886 -7.49336C133.985 -10.1632 135.603 -12.5889 137.644 -14.6306C139.686 -16.6724 142.112 -18.2897 144.782 -19.3893C147.451 -20.4889 150.313 -21.0491 153.2 -21.0375C159.033 -21.0375 164.628 -18.7264 168.761 -14.6102C172.894 -10.4939 175.227 -4.90785 175.25 0.925003ZM136.75 0.925003C136.75 10.025 144.1 17.4625 153.287 17.4625C162.387 17.4625 169.738 10.025 169.738 0.925003C169.738 -8.175 162.387 -15.6125 153.287 -15.6125C144.1 -15.6125 136.75 -8.175 136.75 0.925003Z"
            fill="url(#paint0_linear_3619_22652)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_3619_22652"
          x="33"
          y="-68"
          width="183"
          height="183"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_3619_22652"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3619_22652"
          x1="124.5"
          y1="-64"
          x2="124.5"
          y2="111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#87B5FF" />
          <stop offset="1" stop-color="#ADB5FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const MoreIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="250"
      height="135"
      viewBox="0 0 250 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.2">
        <g filter="url(#filter0_f_3879_22562)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M124.5 -64C76.1759 -64 36.9998 -24.8239 36.9998 23.5C36.9998 71.8239 76.1759 111 124.5 111C172.824 111 212 71.8239 212 23.5C212 -24.8239 172.824 -64 124.5 -64ZM115.686 -0.880672C117.516 -4.48409 118.868 -6.19432 119.99 -7.08522C120.833 -7.75341 121.978 -8.31818 124.5 -8.31818C129.471 -8.31818 132.454 -4.57954 132.454 -0.538635C132.454 1.67273 132.025 2.77045 130.847 4.17046C129.201 6.12727 126.154 8.50569 120.125 12.4909L116.545 14.8455V31.4546C116.545 33.5642 117.383 35.5875 118.875 37.0793C120.367 38.571 122.39 39.4091 124.5 39.4091C126.609 39.4091 128.633 38.571 130.124 37.0793C131.616 35.5875 132.454 33.5642 132.454 31.4546V23.3648C136.766 20.3898 140.385 17.5421 143.01 14.4239C146.804 9.9216 148.363 5.11705 148.363 -0.538635C148.363 -12.2318 139.351 -24.2273 124.5 -24.2273C119.059 -24.2273 114.246 -22.8273 110.118 -19.558C106.268 -16.5114 103.643 -12.3193 101.495 -8.06364C100.996 -7.12923 100.689 -6.10456 100.591 -5.04974C100.493 -3.99493 100.607 -2.93122 100.926 -1.92105C101.245 -0.910873 101.763 0.0254135 102.448 0.832878C103.134 1.64034 103.974 2.30271 104.919 2.78112C105.864 3.25952 106.896 3.54432 107.952 3.6188C109.009 3.69328 110.07 3.55595 111.073 3.21486C112.076 2.87376 113.001 2.33579 113.793 1.6325C114.585 0.929207 115.229 0.0747681 115.686 -0.880672ZM132.454 59.2955C132.454 57.1858 131.616 55.1625 130.124 53.6708C128.633 52.179 126.609 51.3409 124.5 51.3409C122.39 51.3409 120.367 52.179 118.875 53.6708C117.383 55.1625 116.545 57.1858 116.545 59.2955V63.2727C116.545 65.3824 117.383 67.4057 118.875 68.8975C120.367 70.3892 122.39 71.2273 124.5 71.2273C126.609 71.2273 128.633 70.3892 130.124 68.8975C131.616 67.4057 132.454 65.3824 132.454 63.2727V59.2955Z"
            fill="url(#paint0_linear_3879_22562)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_3879_22562"
          x="32.9998"
          y="-68"
          width="183"
          height="183"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_3879_22562"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3879_22562"
          x1="124.5"
          y1="-64"
          x2="124.5"
          y2="111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#87B5FF" />
          <stop offset="1" stop-color="#ADB5FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const items = [
  {
    title: "Discord",
    Icon: DiscordIcon,
  },
  {
    title: "Email",
    Icon: EmailIcon,
  },
  {
    title: "Twitter",
    Icon: TwitterIcon,
  },
  {
    title: "Steam",
    Icon: SteamIcon,
  },
  {
    title: "More to come",
    Icon: MoreIcon,
  },
];

const VCPreview: FunctionComponent = function () {
  return (
    <div
      className={
        "flex flex-wrap justify-center gap-3 w-[550px] sm:gap-6 sm:w-[80vw] mx-auto max-w-[1096px]"
      }
    >
      {items.map((item, index) => {
        return (
          <div
            className="w-[146px] h-[76px] sm:w-[260px] sm:h-[135px] flex items-center justify-center rounded-lg bg-white sm:text-lg font-semibold relative border border-[rgb(183,208,255)] overflow-hidden"
            key={index}
            style={{
              boxShadow: "2px 4px 14px rgba(60, 134, 251, 0.15)",
            }}
          >
            <div className="absolute w-full h-full left-0 top-0 ">
              <item.Icon className="w-full h-full"></item.Icon>
            </div>
            <span className="z-[2]">{item.title}</span>
            <div className="absolute top-1 -right-[2px] sm:top-3 sm:right-4 flex items-center justify-center bg-white rounded-[4px] px-1 py-[2px] z-[3] scale-[0.56] sm:scale-100">
              <CoinIcon className="fill-primary"></CoinIcon>
              <span className="text-[10px] ml-1">100</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VCPreview;
