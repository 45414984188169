import { FunctionComponent } from "react";
import Head from "next/head";
import Image from "next/image";
import {
  DiscordIcon,
  TwitterIcon,
  MediumIcon,
} from "@/form/components/common/icons";
import useAlchemyBadge from "@/hooks/useAlchemyBadge";

function About() {
  return (
    <div>
      <div className="mb-[22px] font-bold">ABOUT</div>
      <a
        href="https://docs.karatdao.com"
        target="_blank"
        className="action mb-[14px] block !text-primary"
        rel="noopener noreferrer"
      >
        Documents
      </a>
      <a
        className="action mb-[14px] block !text-primary"
        href="https://karatdao.notion.site/KaratDAO-Job-Board-e23467773a3148b69002a0ed84683546"
        target="_blank"
        rel="noopener noreferrer"
      >
        Careers
      </a>
      <a
        className="action mb-[14px] block !text-primary"
        href="https://docs.karatdao.com/team-directory"
        target="_blank"
        rel="noopener noreferrer"
      >
        Team
      </a>
    </div>
  );
}

function Support() {
  return (
    <div>
      <div className="mb-[22px] font-bold">SUPPORT</div>
      <a
        href="mailto:info@karatdao.com"
        className="action mb-[14px] block !text-primary"
      >
        Contact Us
      </a>
    </div>
  );
}

function Community() {
  return (
    <div>
      <div className="mb-[22px] font-bold">COMMUNITY</div>
      <a
        className="action mb-5 flex items-center !text-primary"
        target="_blank"
        href="https://discord.gg/fcvFtkfrRA"
        rel="noreferrer"
      >
        <DiscordIcon className="w-5 h-5 fill-primary"></DiscordIcon>
        <span className="ml-2">Discord</span>
      </a>
      <a
        className="action mb-5 flex items-center !text-primary"
        target="_blank"
        href="https://twitter.com/KaratDAO"
        rel="noreferrer"
      >
        <TwitterIcon className="w-5 h-5 fill-primary"></TwitterIcon>
        <span className="ml-2">Twitter</span>
      </a>
      <a
        className="action mb-5 flex items-center !text-primary"
        target="_blank"
        href="https://medium.com/@karatdao"
        rel="noreferrer"
      >
        <MediumIcon className="w-5 h-5 fill-primary"></MediumIcon>
        <span className="ml-2">Medium</span>
      </a>
      <a
        className="action mb-5 flex items-center !text-primary"
        target="_blank"
        href="https://trend3.io/Karatdao"
        rel="noreferrer"
      >
        <Image src="/assets/trend3.png" width={20} height={20}></Image>
        <span className="ml-2">ReadON</span>
      </a>
    </div>
  );
}

const Footer: FunctionComponent = () => {
  const { logBadgeClick } = useAlchemyBadge(
    process.env.NEXT_PUBLIC_ALCHEMY_BADGE as string
  );
  return (
    <>
      <div className="flex w-full flex-wrap justify-between p-10 sm:py-14 sm:px-[120px]">
        <div className="mb-5 text-sm">
          <Image
            src="/airdrop_assets/karatdaoLogo.svg"
            width={150}
            height={40}
            alt="logo"
          ></Image>
          <div className="mt-6 max-w-[334px]">
            Revolutionize and simplify how projects & individuals are able to
            utilize the vast network of on-chain data.
          </div>
          <div className="mt-4 mb-6">
            {new Date().getFullYear()} KaratDAO. All Rights Reserved
          </div>
          <a href="#">
            <img
              onClick={logBadgeClick}
              id="badge-button"
              style={{
                width: 178,
                height: 39,
              }}
              src="https://static.alchemyapi.io/images/marketing/badgeLight.png"
              alt="Alchemy Supercharged"
            />
          </a>
        </div>
        <div className="hidden grid-cols-3 gap-5 sm:grid md:gap-20">
          <About></About>
          <Support></Support>
          <Community></Community>
        </div>
        <div className="flex justify-between w-full mt-10 sm:hidden">
          <div>
            <About></About>
            <div className="mt-10">
              <Support></Support>
            </div>
          </div>
          <Community></Community>
        </div>
      </div>
    </>
  );
};

export default Footer;
