import { FunctionComponent, useEffect } from "react";
import scrollMonitor, { Listener } from "scrollmonitor";

const rolesLink = "https://docs.karatdao.com/roles";

const ChooseRole: FunctionComponent = () => {
  useEffect(() => {
    const containerMonitor = scrollMonitor.createContainer(
      document.getElementById("container") as any
    );
    const validatorElement = document.getElementById("validator")!;
    const verifierElement = document.getElementById("verifier")!;
    if (!validatorElement) {
      return;
    }
    const slideListener: Listener = (evt, watcher) => {
      if (!watcher) {
        return;
      }
      if (watcher.isBelowViewport) {
        if (watcher.isInViewport) {
          (watcher.watchItem as any).classList.add(
            "animate__animated",
            "animate__slideInUp",
            "!visible"
          );
        } else {
          (watcher.watchItem as any).classList.remove(
            "animate__animated",
            "animate__slideInUp",
            "visible"
          );
        }
      }
    };

    const validator = containerMonitor.create(validatorElement);
    const slideRightListener: Listener = (_, watcher) => {
      if (!watcher) {
        return;
      }
      if (watcher.isBelowViewport) {
        if (watcher.isInViewport) {
          validatorElement.classList.add(
            "animate__animated",
            "animate__fadeInRight",
            "animate__faster",
            "!visible"
          );
        } else {
          validatorElement.classList.remove(
            "animate__animated",
            "animate__fadeInRight",
            "animate__faster",
            "!visible"
          );
        }
      }
    };
    validator.on("visibilityChange", slideRightListener);

    const verifier = containerMonitor.create(verifierElement);
    const slideLeftListener: Listener = (_, watcher) => {
      if (!watcher) {
        return;
      }
      if (watcher.isBelowViewport) {
        if (watcher.isInViewport) {
          verifierElement.classList.add(
            "animate__animated",
            "animate__fadeInLeft",
            "animate__faster",
            "!visible"
          );
        } else {
          verifierElement.classList.remove(
            "verifierElement",
            "animate__fadeInLeft",
            "animate__faster",
            "!visible"
          );
        }
      }
    };
    verifier.on("visibilityChange", slideLeftListener);
    return () => {
      verifier.off("visibilityChange", slideLeftListener);
      validator.off("visibilityChange", slideRightListener);
    };
  }, []);
  return (
    <div className="layout-content flex flex-col items-center py-[120px]">
      <div
        className="selection:text-fill-white font-extrabold text-[40px] sm:text-[56px] leading-none max-w-[643px] text-center"
        style={{
          background:
            "linear-gradient(94.94deg, #0E0909 5.6%, #000000 5.61%, #676767 86.73%)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        Choose your role and participate
      </div>
      <div className="text-center whitespace-pre-line mt-4 font-medium text-dark2">{`Two NFTs, One Ecosystem
Network powered by dual-NFT tokenomic design`}</div>
      {/* pc */}
      <div
        className="hidden sm:flex justify-center w-full mt-[52px] z-10"
        style={{
          background:
            "linear-gradient(180deg, rgba(244, 219, 140, 0) 0%, rgba(244, 219, 140, 0.1) 54.69%, rgba(244, 219, 140, 0) 100%)",
        }}
      >
        <div
          className="bg-[#12162B] -mr-2 rounded-lg px-6 h-[28vw] flex-shrink-0 w-[27vw] flex flex-col items-center justify-center mt-[1.5vw] translate-x-[100%]"
          id="validator"
          style={{
            boxShadow: "inset 10px 4px 19px rgba(248, 225, 225, 0.3)",
            backdropFilter: "blur(2px)",
          }}
        >
          <div className="text-white text-2xl font-semibold">Validator</div>
          <div className="text-[#E0D8D8] whitespace-pre-line mt-4 text-center text-sm lg:text-base">
            {`・ Pay to mint
・ Tradable
・ Backed by referral number
・ For influential leaders`}
          </div>
          <a
            className="mt-6 text-brand2 font-semibold action"
            href={rolesLink}
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </div>
        <div
          className="hidden sm:block z-10 w-[29vw] flex-shrink-0"
          style={{
            filter: "drop-shadow(0px 4px 40px #E0E0FB)",
          }}
        >
          <div
            style={{
              filter: "drop-shadow(2px 4px 16px rgba(0, 0, 0, 0.05))",
            }}
          >
            <img src="/assets/network/network-role.png"></img>
          </div>
        </div>
        <div
          id="verifier"
          className="bg-[#12162B] -ml-2 rounded-lg px-6 h-[28vw] flex-shrink-0 w-[27vw] hidden sm:flex flex-col items-center justify-center mt-[6.5vw] -translate-x-[100%]"
          style={{
            boxShadow: "inset 10px 4px 19px rgba(248, 225, 225, 0.3)",
            backdropFilter: "blur(2px)",
          }}
        >
          <div className="text-white text-2xl font-semibold">Karat ID</div>
          <div className="text-[#E0D8D8] mt-4 text-center whitespace-pre-line text-sm lg:text-base">
            {`・ Mint fee 5U
・ SoulBound Token
・ Backed by your credentials
・ For users`}
          </div>
          <a
            className="mt-6 text-brand2 font-semibold action"
            href={rolesLink}
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </div>
      </div>
      {/* mobile */}
      <div
        className="sm:hidden bg-[#12162B] w-[72%] rounded-lg p-8 pb-12 flex-shrink-0  flex flex-col items-center justify-center mt-10 -mb-2"
        style={{
          boxShadow: "inset 10px 4px 19px rgba(248, 225, 225, 0.3)",
          backdropFilter: "blur(2px)",
        }}
      >
        <div className="text-white font-semibold">Validator</div>
        <div className="text-[#E0D8D8] whitespace-pre-line mt-4 text-center text-sm lg:text-base">
          {`・ Pay to mint
・ Tradable
・ Backed by referral number
・ For influential leaders`}
        </div>
        <a
          className="mt-6 text-brand2 font-semibold action"
          href={rolesLink}
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      </div>
      <div
        className="sm:hidden z-10 w-[81%] flex-shrink-0"
        style={{
          filter: "drop-shadow(0px 4px 40px #E0E0FB)",
        }}
      >
        <div
          style={{
            filter: "drop-shadow(2px 4px 16px rgba(0, 0, 0, 0.05))",
          }}
        >
          <img src="/assets/network/network-role.png"></img>
        </div>
      </div>
      <div
        className="sm:hidden bg-[#12162B] rounded-lg p-8 pt-12 flex-shrink-0 w-[72%] flex flex-col items-center justify-center -mt-2 "
        style={{
          boxShadow: "inset 10px 4px 19px rgba(248, 225, 225, 0.3)",
          backdropFilter: "blur(2px)",
        }}
      >
        <div className="text-white  font-semibold">Karat ID</div>
        <div className="text-[#E0D8D8] mt-4 text-center whitespace-pre-line text-sm lg:text-base">
          {`・ Mint fee 5U
・ SoulBound Token
・ Backed by your credentials
・ For users`}
        </div>
        <a
          className="mt-6 text-brand2 font-semibold action"
          href={rolesLink}
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      </div>
    </div>
  );
};

export default ChooseRole;
