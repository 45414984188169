import { FunctionComponent, PropsWithChildren } from "react";

const Button: FunctionComponent<
  {
    className?: string;
    onClick?: any;
    theme?: "primary" | "dark";
    type?: "default" | "outline";
  } & PropsWithChildren
> = ({
  children,
  className = "",
  theme = "primary",
  type = "default",
  onClick,
}) => {
  return (
    <div
      className={`flex cursor-pointer select-none items-center justify-center rounded-full ${
        theme === "dark"
          ? `${
              type === "outline"
                ? "border-[2px] border-[#292939] text-[#292939] hover:bg-[#292939] hover:text-white"
                : "bg-[#292939] text-white hover:bg-[#292939]/90"
            }`
          : `${
              type === "outline"
                ? "border-[2px] border-primary text-primary hover:opacity-80"
                : "bg-primary text-white hover:bg-primary/90"
            }`
      } h-[56px]  w-[242px] text-sm transition-all  ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Button;
