import { FunctionComponent, SVGProps } from "react";

const DataOwnship: FunctionComponent = () => {
  return (
    <>
      <div className="hidden sm:flex layout-block flex-col items-center py-[120px] px-[186px]">
        <div
          className="selection:text-fill-white font-extrabold text-[56px] leading-none "
          style={{
            background:
              "linear-gradient(94.94deg, #0E0909 5.6%, #000000 5.61%, #676767 86.73%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Future of data ownership{" "}
        </div>
        <div className="font-medium text-dark2 mt-4">
          Bridge the gap between Web2 data and Web3 addresses
        </div>
        <div
          className="w-full mt-10 rounded-[20px] bg-white pl-20 flex items-center"
          style={{
            filter: "drop-shadow(0px 4px 25px #EEF4FC)",
          }}
        >
          <div className="w-[340px] translate-y-5">
            <div className="uppercase font-bold text-dark2">For Projects</div>
            <div className="font-bold text-dark1 text-[28px] mt-4 leading-[37px]">
              Request insights from Karat Network
            </div>
            <div className="text-dark1 leading-[24px]">
              Understand the people behind the wallets
            </div>
          </div>
          <div className="flex-1 flex items-center -ml-10">
            <div
              className="h-[397px] w-full bg-no-repeat bg-cover z-10"
              style={{
                backgroundImage: 'url("/assets/home/ownership-1.png")',
                backgroundPosition: "-30px 0px",
              }}
            ></div>
          </div>
        </div>
        <div
          className="w-full mt-6 rounded-[20px] bg-white pr-20 flex items-center"
          style={{
            filter: "drop-shadow(0px 4px 25px #EEF4FC)",
          }}
        >
          <div className="flex-1">
            <img src="/assets/home/ownership-2.png"></img>
          </div>
          <div className="w-[340px] -translate-x-4">
            <div className="uppercase font-bold text-dark2">For Users</div>
            <div className="font-bold text-dark1 text-[28px] mt-4 leading-[37px]">
              Authorize your data with confidence
            </div>
            <div className="text-dark1 leading-[24px]">
              Exchange values with the network by providing data and interacting
            </div>
            {/* <div className="text-primary font-medium mt-4 action">
              Learn more
            </div> */}
          </div>
        </div>
      </div>
      <div className="layout-content flex sm:hidden flex-col items-center py-[85px] px-5">
        <div
          className="selection:text-fill-white font-extrabold text-[40px] leading-none text-center"
          style={{
            background:
              "linear-gradient(94.94deg, #0E0909 5.6%, #000000 5.61%, #676767 86.73%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Future of data ownership{" "}
        </div>
        <div className="font-medium text-dark2 mt-4 text-center">
          Bridge the gap between Web2 data and Web3 addresses
        </div>
        <div
          className="w-full mt-10 rounded-[6px] bg-white flex flex-col"
          style={{
            boxShadow: "2px 4px 16px rgba(0, 0, 0, 0.05)",
          }}
        >
          <div className="px-6 pt-8">
            <div className="uppercase font-bold text-dark2 text-xs">
              For Projects
            </div>
            <div className="font-bold text-dark1 text-[20px] mt-4 leading-[27px]">
              Request insights from Karat Network
            </div>
            <div className="text-dark1 leading-[19px] mt-2 text-sm">
              Understand the people behind the wallets
            </div>
          </div>
          <div className="flex-1 -mt-2">
            <img src="/assets/home/ownership-m-1.png"></img>
          </div>
        </div>
        <div
          className="w-full mt-6 rounded-[6px] bg-white flex flex-col"
          style={{
            boxShadow: "2px 4px 16px rgba(0, 0, 0, 0.05)",
          }}
        >
          <div className="px-6 pt-8">
            <div className="uppercase font-bold text-dark2 text-xs">
              For Users
            </div>
            <div className="font-bold text-dark1 text-[20px] mt-4 leading-[27px]">
              Authorize your data with confidence
            </div>
            <div className="text-dark1 leading-[19px] mt-2 text-sm">
              Exchange values with the network by providing data and interacting
            </div>
            {/* <div className="text-primary font-medium mt-4 action">
              Learn more
            </div> */}
          </div>

          <div className="flex-1 -mt-5">
            <img src="/assets/home/ownership-m-2.png"></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataOwnship;
