import { FunctionComponent } from "react";

const images = [
  "/assets/network/claimer/scientist.png",
  "/assets/network/claimer/engineer.png",
  "/assets/network/claimer/artist.png",
  "/assets/network/claimer/security.png",
  "/assets/network/claimer/doctor.png",
];

const ClaimerNFTPreview: FunctionComponent = function () {
  return (
    <div
      className={
        "grid grid-cols-5 gap-3 sm:gap-5 max-w-[1096px] w-[95vw] sm:w-[80vw]"
      }
    >
      {images.map((img, index) => {
        return (
          <img
            src={img}
            className="rounded-lg bg-transparent odd:mt-6"
            style={{
              filter: "drop-shadow(2px 4px 20px rgba(173, 181, 255, 0.5))",
            }}
            alt=""
            key={index}
          />
        );
      })}
    </div>
  );
};

export default ClaimerNFTPreview;
