import { FunctionComponent, useEffect } from "react";
import Button from "@/components/homepage/Button";
import { useRouter } from "next/router";
import scrollMonitor, { Listener } from "scrollmonitor";

const Mission: FunctionComponent = () => {
  const router = useRouter();

  useEffect(() => {
    const containerMonitor = scrollMonitor.createContainer(
      document.getElementById("container") as any
    );
    const rabbitElement = document.getElementById("rabbit")!;
    if (!rabbitElement) {
      return;
    }

    const rabbit = containerMonitor.create(rabbitElement);
    const slideRightListener: Listener = (_, watcher) => {
      if (!watcher) {
        return;
      }
      if (watcher.isBelowViewport) {
        if (watcher.isInViewport) {
          rabbitElement.classList.add(
            "animate__animated",
            "animate__slideInRight",
            "animate__slow",
            "!visible"
          );
        } else {
          rabbitElement.classList.remove(
            "animate__animated",
            "animate__slideInRight",
            "animate__slow",
            "!visible"
          );
        }
      }
    };
    rabbit.on("visibilityChange", slideRightListener);

    return () => {
      rabbit.off("visibilityChange", slideRightListener);
    };
  }, []);

  return (
    <div className="layout-content mb-[170px] mt-[20px] sm:mt-[80px] relative">
      <div
        className="absolute w-full h-[600px] sm:h-[410px] top-0 z-0"
        style={{
          borderRadius: "1440px",
          background: "linear-gradient(180deg, #ADB5FF 0%, #F8E1E0 100%)",
          filter: "blur(92.1875px)",
        }}
      ></div>
      <div className="bg-network h-[521px] sm:h-[351px] bg-center bg-cover sm:flex flex-col sm:flex-row sm:items-center relative z-10">
        <div className="ml-11 sm:ml-[119px] flex-shrink-0 pt-[89px] sm:pt-0">
          <div className="text-white font-extrabold text-[40px] sm:text-[56px] w-[254px] sm:w-auto">
            Ready to join the mission?
          </div>
          <Button
            className="mt-6"
            onClick={() => {
              router.push("/network/mission");
            }}
          >
            Learn More
          </Button>
        </div>
        <img
          id="rabbit"
          src="/airdrop_assets/homepage/rabbit.png"
          className="rabbit w-[214px] sm:w-[404px] mt-[25px] sm:-mt-[40px] sm:ml-10 ml-[125px] translate-x-full invisible"
        ></img>
      </div>
    </div>
  );
};

export default Mission;
