import { FunctionComponent, useEffect, useState } from "react";
import { numberWithCommas } from "@/utils/format";
import { NetworkInfo } from "@/types/network";
import { getNetworkInfo } from "@/services/network";

const NetworkData: FunctionComponent = () => {
  const [networkInfo, setNetworkInfo] = useState<NetworkInfo>();
  const [items, setItems] = useState<{label: string, value: string}[]>([]);
 
  useEffect(() => {
    setItems([
      {
        label: "Claimer Minted",
        value: `${numberWithCommas(networkInfo?.claimerMinted ?? 0)}+`,
      },
      {
        label: "VC Minted",
        value: `${numberWithCommas(networkInfo?.vcMinted ?? 0)}+`,
      },
      {
        label: "Karat IDs Created",
        value: `${numberWithCommas(networkInfo?.karatIDCreated ?? 0)}+`,
      },
    ])
  }, [networkInfo]);

  useEffect(() => {
    getNetworkInfo().then(setNetworkInfo);
  }, []);

  return (
    <div className="layout-block layout-content  px-16 pb-[100px] sm:py-10 sm:px-12 font-exo">
      <div className="flex justify-around flex-wrap gap-14">
        {items.map((item, index) => {
          return (
            <>
              <div key={index} className="text-center">
                <div className="text-[48px] font-extrabold text-tertiary">
                  {item.value}
                </div>
                <div className="mt-2 text-[28px] font-light text-dark1">
                  {item.label}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default NetworkData;
