import { FunctionComponent, useEffect, useRef, useState } from "react";
import Link from "next/link";
import Button from "@/components/homepage/Button";
import { Button as MantineButton } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";
import ClaimerNFTPreview from "@/components/homepage/ClaimerNFTPreview";
import VCPreview from "@/components/homepage//VCPreview";

const MintIntro: FunctionComponent = () => {
  const autoplay = useRef(Autoplay({ delay: 3000 }));
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <div className="relative overflow-hidden">
      <div
        className="h-[600px] sm:h-[860px]"
        style={{
          background:
            "linear-gradient(180deg, rgba(221, 233, 252, 0.7) 50.7%, rgba(221, 233, 252, 0) 100%)",
        }}
      >
        <div className="relative w-full h-full">
          <div
            className={`w-full h-full absolute left-0 top-0 flex items-center justify-center ${
              visible ? "visible" : "invisible"
            }`}
          >
            <Carousel
              plugins={[autoplay.current]}
              loop
              slideSize="100%"
              withIndicators
              onMouseEnter={autoplay.current.stop}
              onMouseLeave={autoplay.current.reset}
              classNames={{
                indicators: "!-bottom-[30px] !gap-4",
                indicator: "w-2 h-2 bg-[#E0E1E1] ",
              }}
              styles={{
                indicator: {
                  "&[data-active]": {
                    backgroundColor: "#3C86FB",
                  },
                },
              }}
            >
              <Carousel.Slide className="bg-center bg-no-repeat bg-contain bg-homeHeroBg">
                <div className="relative flex flex-col items-center ">
                  <ClaimerNFTPreview></ClaimerNFTPreview>
                  <div
                    className="mt-4 selection:text-fill-white font-extrabold text-[37px] sm:text-[80px] leading-none z-10"
                    style={{
                      background:
                        "linear-gradient(94.94deg, #0E0909 5.6%, #000000 5.61%, #676767 86.73%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Karat ID
                  </div>
                  <div className="whitespace-pre-line sm:text-xl text-center mt-4  text-dark1 max-w-[95vw]">
                    Build your on-chain profile and name service
                  </div>
                  <div className="mt-1 font-semibold sm:text-xl text-dark1">
                    Unlimited supply
                  </div>
                  <Link href={"/mint/claimer"}>
                    <Button className="mt-12" theme="dark">
                      Claim
                    </Button>
                  </Link>
                </div>
              </Carousel.Slide>

              <Carousel.Slide className="bg-center bg-no-repeat bg-contain bg-idoBannerBg">
                <div className="relative flex flex-col items-center overflow-hidden">
                  <img
                    src="/assets/ido/rock.png"
                    className="-mt-10 sm:-mt-20 w-[550px] sm:w-[900px]"
                  />

                  <div
                    className=" -mt-4 sm:-mt-12 selection:text-fill-white font-extrabold text-[37px] sm:text-[80px] leading-tight z-20"
                    style={{
                      background:
                        "linear-gradient(94.94deg, #0E0909 5.6%, #000000 5.61%, #676767 86.73%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Mainnet token launch
                  </div>
                  <div className="whitespace-pre-line sm:text-xl text-center mt-4  text-dark1 max-w-[95vw] z-20">
                    70,000,000 KAT tokens available for mining
                  </div>

                  <Link href={"/network/mining"}>
                    <Button className="z-20 mt-12" theme="dark">
                      Join Now
                    </Button>
                  </Link>
                </div>
              </Carousel.Slide>

              <Carousel.Slide className="bg-center bg-no-repeat bg-contain bg-homeHeroBg">
                <div className="relative flex flex-col items-center overflow-hidden">
                  <VCPreview></VCPreview>
                  {/* <div className="text-primary font-bold uppercase mt-[60px]">
                    FREE MINT
                  </div> */}
                  <div
                    className="mt-8 selection:text-fill-white font-extrabold text-[37px] sm:text-[80px] leading-none z-10"
                    style={{
                      background:
                        "linear-gradient(94.94deg, #0E0909 5.6%, #000000 5.61%, #676767 86.73%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Verifiable Credential
                  </div>
                  <div className="whitespace-pre-line sm:text-xl text-center mt-4  text-dark1 max-w-[95vw]">
                    Encrypt and verify your social accounts through MPC and
                    bridge web2 data on chain
                  </div>
                  {/* <div className="mt-1 font-semibold sm:text-xl text-dark1">
                    Unlimited supply
                  </div> */}
                  <Link href={"/dashboard/credential"}>
                    <Button className="mt-12" theme="dark">
                      Verify
                    </Button>
                  </Link>
                </div>
              </Carousel.Slide>
            </Carousel>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-between items-center pb-14 px-5 sm:pl-[110px] sm:pr-[80px] sm:pb-[70px]">
        <div className="flex-1 sm:mr-20 ">
          <div className="font-extrabold text-[36px] text-dark2">
            What’s Karat Network
          </div>
          <div className="mt-4 text-dark1 text-lg min-w-[330px]">
            A fully decentralized data network that connects Web2 to Web3.
            Powered by MPC and ZK technologies.
          </div>

          <MantineButton
            variant="outline"
            radius="xl"
            size="md"
            classNames={{
              root: "mt-6 w-[149px]",
            }}
            onClick={() => {
              window.open("https://docs.karatdao.com/karat-network", "_blank");
            }}
          >
            Learn More
          </MantineButton>
        </div>
        <img
          src="/assets/network/network-group.png"
          className="flex-shrink mt-10 sm:mt-0 w-[477px]"
        ></img>
      </div>
    </div>
  );
};

export default MintIntro;
