import { FunctionComponent } from "react";
import Image from "next/image";
import Button from "@/components/homepage/Button";
import Link from "next/link";

const FormIntro: FunctionComponent = () => {
  return (
    <>
      <div className="flex flex-col items-center">
        <div
          className="selection:text-fill-white font-extrabold text-[40px] sm:text-[56px] leading-none  w-[341px] sm:w-[643px] text-center"
          style={{
            background:
              "linear-gradient(94.94deg, #0E0909 5.6%, #000000 5.61%, #676767 86.73%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Web3 growth hacking with Karat dApps
        </div>
        <div className="mt-4 sm:whitespace-pre-line text-center px-14 sm:px-0">{`Grow your community with our dApps.
Powered by Web2 data from Karat network that’s coming soon`}</div>
      </div>
      <div
        id="form-anchor"
        className="layout-block relative z-[4] block overflow-visible px-5 py-5  sm:px-12"
      >
        {/* form step 1 */}
        <div
          className="z-[3] mb-[9vh] mt-14  flex h-[640px] w-full flex-wrap items-center rounded-lg shadow-card1 sm:h-auto overflow-hidden"
          style={{
            background: "linear-gradient(180deg, #9AC0FC 0%, #FFFFFF 100%)",
          }}
        >
          <div className="flex  flex-1 items-center">
            <div className="mx-6 pt-6 lg:mx-[60px]">
              <div className="pr-5 text-2xl font-bold text-dark1">
                Karat Form
              </div>
              <div className="mt-2 pr-4 font-normal text-dark1 w-[330px]">
                The simplest way to collect wallet addresses and social accounts
                for your NFT whitelist, raffle, and AMA.
              </div>
              <div className="text-primary font-medium mt-2 action mb-7 ">
                Learn more
              </div>
              <div
                data-track="home_get_start"
                data-track-params={`{"position": "create"}`}
              >
                <Link href={"/dashboard/forms/start_create"}>
                  <Button theme="dark">Get started for free</Button>
                </Link>
              </div>
            </div>
          </div>
          <img
            className="mt-6 block ml-10  sm:hidden"
            src="/airdrop_assets/homepage/form-step-1.png"
          ></img>
          <div
            className="flex-1 relative mx-4 mr-[10%] hidden  sm:block h-[425px] w-[480px]"
            style={{
              backgroundImage:
                'url("/airdrop_assets/homepage/form-step-1.png")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center -25px",
            }}
          ></div>
        </div>
        {/* form step 2 */}
        <div
          id="analytics-anchor"
          className=" z-[2] mb-[2vh] mt-[-7vh] flex  h-[640px] w-full flex-wrap items-center rounded-lg shadow-card1 sm:h-auto overflow-hidden"
          style={{
            background: "linear-gradient(180deg, #9AC0FC 0%, #FFFFFF 100%)",
          }}
        >
          <div className="flex w-full flex-1 items-center ">
            <div className="mx-6 pt-6 lg:mx-[60px]">
              <div className="text-2xl font-bold text-dark1">
                Wallet Dashboard
              </div>
              <div className="mt-2 font-normal text-dark1 w-[330px]">
                Explore in-depth insights with your customized wallet list,
                Galaxy campaign, or NFT
              </div>
              <div className="text-primary font-medium mt-2 action mb-7 ">
                Learn more
              </div>
              <div
                data-track="home_get_start"
                data-track-params={`{"position": "response"}`}
              >
                <Link href={"/dashboard/analytics"}>
                  <Button theme="dark">Get started for free</Button>
                </Link>
              </div>
            </div>
          </div>
          <img
            className="mt-6 block pb-3 sm:hidden ml-10"
            src="/airdrop_assets/homepage/m-form-step-2.png"
          ></img>

          <div
            className="flex-1 relative hidden sm:block h-[385px] w-[520px] mr-[10%]"
            style={{
              backgroundImage:
                'url("/airdrop_assets/homepage/form-step-2.png")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>

        {/* form step 3 */}
        <div
          id="airdrops-anchor"
          className="z-[1] flex h-[640px] w-full flex-wrap items-center rounded-lg shadow-card1 sm:h-auto overflow-hidden"
          style={{
            background: "linear-gradient(180deg, #9AC0FC 0%, #FFFFFF 100%)",
          }}
        >
          <div className="flex w-full flex-1 items-center">
            <div className="mx-6 pt-6 lg:mx-[60px]">
              <div className="text-2xl font-bold text-dark1">
                Airdrop Launchpad
              </div>
              <div className="mt-2 font-normal text-dark1 w-[330px]">
                Send token or NFT airdrops to your community and potential
                audiences
              </div>
              <div className="text-primary font-medium mt-2 action mb-7 ">
                Learn more
              </div>
              <div
                data-track="home_get_start"
                data-track-params={`{"position": "reward"}`}
              >
                <Link href={"/dashboard/airdrops"}>
                  <Button theme="dark">Get started for free</Button>
                </Link>
              </div>
            </div>
          </div>
          <img
            className="mt-6 block sm:hidden ml-5"
            src="/airdrop_assets/homepage/m-form-step-3.png"
          ></img>
          <div
            className="flex-1 relative hidden h-[210px] w-[310px] sm:block md:h-[405px] md:w-[550px] mr-[10%]"
            style={{
              backgroundImage:
                'url("/airdrop_assets/homepage/form-step-3-1.png")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default FormIntro;
