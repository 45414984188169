import { use100vh } from "react-div-100vh";
import Footer from "@/components/common/Footer";
import Nav from "@/components/common/Nav";
import Hero from "@/components/homepage/Hero";
import FeaturesIntro from "@/components/homepage/FeaturesIntro";
import FormIntro from "@/components/homepage/FormIntro";
import Partners from "@/components/homepage/Partners";
import DataOwnship from "@/components/homepage/DataOwnship";
import ChooseRole from "@/components/homepage/ChooseRole";
import Investors from "@/components/homepage/Investors";
import Mission from "@/components/homepage/Mission";
import PageHead from "@/components/common/PageHead";
import MintIntro from "@/components/homepage/MintIntro";
import NetworkData from "@/components/homepage/NetworkData";
import { useEffect } from "react";
import { useRouter } from "next/router";

const Home = () => {
  const height = use100vh();
  const router = useRouter();

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    if (hash) {
      const target = document.getElementById(`${hash}-anchor`);
      if (target) {
        target.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    }
  }, [router]);

  return (
    <div
      id="container"
      style={{
        height: height ? `${height}px` : "100vh",
      }}
      className="w-screen overflow-x-hidden overflow-y-scroll bg-white font-exo"
    >
      <PageHead></PageHead>
      <Nav></Nav>
      <MintIntro></MintIntro>
      <FeaturesIntro></FeaturesIntro>
      <DataOwnship></DataOwnship>
      <Partners></Partners>
      <ChooseRole></ChooseRole>
      <NetworkData></NetworkData>
      <Mission></Mission>
      <FormIntro></FormIntro>
      <Investors></Investors>
      <div className="bg-dark1 text-white">
        <div className="layout-block">
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
};

export default Home;
