import { FunctionComponent } from "react";
import Image from "next/image";
import InfiniteLoopScroll from "@/components/homepage/InfiniteLoopScroll";

const partners = [
  "ANKR.svg",
  "Delysium.svg",
  "HuobiGlobal.svg",
  "HYPEX.svg",
  "KuCoinCommunity.svg",
  "KuCoinVentures.svg",
  "LAAGLabs.svg",
  "MetaForce.svg",
  "Nswap.svg",
  "367.svg",
  "377.svg",
];

const Partners: FunctionComponent = () => {
  return (
    <div className="layout-block layout-content  px-5 py-0 sm:py-10 sm:px-28">
      <div className="mb-9 text-[28px] font-bold">Our ecosystem partners</div>
      <div className="relative mx-auto flex flex-shrink-0 flex-col overflow-hidden sm:w-[65vw]">
        <InfiniteLoopScroll duration={"10000ms"}>
          {partners.map((partner) => (
            <img
              src={`/airdrop_assets/home-page-img/SupportedBrands/${partner}`}
              key={partner}
              className="h-20 w-20"
            />
          ))}
        </InfiniteLoopScroll>
        <div
          className="pointer-events-none absolute inset-0"
          style={{
            background:
              "linear-gradient(90deg, #ffffff, transparent 30%, transparent 70%, #ffffff)",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Partners;
