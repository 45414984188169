import { NetworkInfo } from "@/types/network";
import request from "@/utils/request";

export const getRefereesTable = async (data: {
  character: string;
  validatorTokenId: string;
  current: number;
  pageSize: number;
}): Promise<{
  list: {
    claimer: string;
    karatScore: string;
    joinDate: string;
    lieutenant: string;
  }[];
  pageSize: number;
  pageNum: number;
  size: number;
  next: number;
  total: number;
  pages: number;
}> => {
  if (!data.character || typeof data.validatorTokenId === "undefined") {
    return {
      list: [],
      pageSize: 0,
      pageNum: 0,
      size: 0,
      next: 0,
      total: 0,
      pages: 0,
    };
  }
  // @ts-ignore
  return await request.rpc("network/getRefereesTable", data, {
    endpoint: "network",
  });
};

export interface DataType {
  claimer: string;
  karatScore: number;
  joinDate: string;
  lieutenant: string;
}

export const getNetworkInfo = async (): Promise<NetworkInfo> => {
  // @ts-ignore
  return await request.rpc("network/getNetworkInfo", {});
};

export const getVestingClaimInfo = async (data: {
  address: any;
  vestingId: string;
}): Promise<{
  amount: number;
  proof: string[];
}> => {
  // @ts-ignore
  return await request.rpc("network/getVestingClaimInfo", data);
};

export const getAirdropClaimInfo = async (data: {
  address: any;
}): Promise<{
  amount: number;
  proof: string[];
}> => {
  // @ts-ignore
  return await request.rpc("network/getAirdropClaimInfo", data);
};

export const getValidatorAirdropClaimInfo = async (data: {
  address: any;
}): Promise<{
  tokenIds: number[];
  amounts: number[];
  merkleProofs: string[][];
}> => {
  // @ts-ignore
  return await request.rpc("network/getValidatorAirdropClaimInfo", data);
};
